@media (max-width: 1200px) {
  /* line 6, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav > li > a {
    padding: 0 15px; }
  /* line 10, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .works-grid-3-col-wide .container-fluid {
    padding: 0 15px; }
  /* line 14, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .result-box {
    padding: 40% 0; } }

@media (max-width: 991px) {
  /* line 23, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .section-wrap,
  .section-wrap-mp {
    background-attachment: scroll; }
  /* line 28, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .section-wrap {
    padding: 90px 0; }
  /* line 32, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .service-item-box,
  .team-member,
  .blog-col-3 {
    margin-bottom: 40px; }
  /* line 38, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .promo-description {
    padding: 40px; }
  /* line 42, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .grid-3-col.grid-gutter .work-item {
    width: 50%; }
  /* line 46, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .call-to-action h2,
  .cta-button {
    text-align: center; }
  /* line 51, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .call-to-action h2 {
    margin-bottom: 30px;
    line-height: 1.5; }
  /* line 56, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .contact-item {
    border-right: none;
    margin-bottom: 50px; }
  /* line 61, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .page-title .container {
    height: 300px; }
  /* line 65, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .title-text {
    padding-top: 50px; }
  /* line 69, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .title-text h1 {
    font-size: 28px; }
  /* line 73, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .blog-standard .sidebar,
  .blog-single .sidebar {
    padding-left: 30px; }
  /* line 78, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .blog-standard .entry-title,
  .blog-standard .entry-meta {
    padding-left: 0; }
  /* line 83, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .blog-standard .entry {
    margin-top: 20px; }
  /* line 87, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .entry-content .entry-share {
    float: none; }
  /* line 91, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  #portfolio.angle-bottom:after {
    margin-top: 200px; }
  /* line 95, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .parallax-testimonials .owl-pagination {
    bottom: 140px; }
  /* line 99, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-2 .navbar,
  .nav-type-4 .navbar,
  .nav-type-4 .nav-left {
    min-height: 60px; }
  /* line 105, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-2 .navbar-nav {
    padding: 0; }
  /* line 109, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-2 .navbar-collapse,
  .nav-type-4 .navbar-collapse {
    border-top: 1px solid #f2f2f2; }
  /* line 114, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-2 .nav-wrap {
    padding-left: 0;
    padding-right: 0;
    width: 100%; }
  /* line 120, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .dropdown-menu {
    padding: 0; }
  /* line 124, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .dropdown-menu,
  .dropdown-submenu > .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible; }
  /* line 131, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-menu {
    width: auto !important; }
  /* line 135, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-2 .nav > li > a {
    padding: 15px 0 15px 15px;
    border-bottom: 1px solid #f2f2f2;
    line-height: 20px; }
  /* line 141, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid #f2f2f2; }
  /* line 146, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-submenu .dropdown-menu > li > a {
    padding: 15px 0 15px 30px; }
  /* line 150, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-submenu .dropdown-menu > li > ul > li > a {
    padding: 15px 0 15px 45px; }
  /* line 154, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar .navbar-collapse.in .navbar-nav .dropdown > a:after,
  .dropdown-submenu > a:after {
    font-family: "FontAwesome";
    position: absolute;
    content: "\f107";
    right: 15px;
    color: #45464b; }
  /* line 163, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-menu > li > a {
    color: #7a7a7a; }
  /* line 167, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-menu > li > a:focus {
    background-color: transparent; }
  /* line 171, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #cc1236; }
  /* line 175, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .navbar-nav > li > a.nav-search {
    display: none; }
  /* line 179, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  #mobile-search {
    display: block; }
  /* line 183, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .mobile-search .form-control {
    height: 51px;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 0; }
  /* line 191, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  #mobile-search .search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 51px;
    border: 0;
    cursor: pointer;
    background-color: transparent; }
  /* line 202, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .pricing-3-col {
    margin-bottom: 30px; }
  /* line 206, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .widget {
    margin-bottom: 40px; }
  /* line 210, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .page-title.style-2 .title-text {
    padding-top: 0; }
  /* line 214, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .portfolio-description {
    padding-left: 0;
    margin-top: 30px; }
  /* line 219, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .masonry-grid .work-item.quarter {
    width: 50%; }
  /* line 223, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .intro.style-2 .intro-text p {
    font-size: 36px; }
  /* line 227, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .about-me .info {
    padding-left: 0; }
  /* line 231, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .content-wrap {
    margin-left: 0; }
  /* line 235, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 {
    width: 100%;
    height: auto;
    position: fixed; }
  /* line 241, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 .navbar-header {
    margin: 0; }
  /* line 245, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 .header-wrap {
    width: 100%;
    padding: 0 15px; }
  /* line 250, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 .logo-container {
    width: auto;
    padding: 0 15px; }
  /* line 255, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 .nav {
    margin: 0; }
  /* line 259, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-4 .logo-wrap > a {
    height: 60px; }
  /* line 263, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  #ytb-wrap .hero-text {
    font-size: 76px; } }

@media (max-width: 767px) {
  /* line 272, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .section-wrap {
    padding: 80px 0; }
  /* line 276, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .intro-heading {
    font-size: 22px; }
  /* line 280, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .heading-frame {
    padding: 24px 30px; }
  /* line 284, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .grid-3-col.grid-gutter .work-item {
    width: 100%; }
  /* line 288, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .process-item {
    margin-bottom: 40px; }
  /* line 292, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .our-team .container-fluid {
    padding: 0 15px; }
  /* line 296, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .client-logo {
    border-right: none; }
  /* line 300, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .second-row .client-logo {
    border-bottom: 1px solid #dedede; }
  /* line 304, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .second-row .client-logo:last-child {
    border-bottom: none; }
  /* line 308, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .blog-standard .sidebar,
  .blog-single .sidebar {
    padding-left: 15px;
    margin-top: 50px; }
  /* line 314, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .hero-message h1,
  .hero-message.text-rotator h1 {
    font-size: 38px; }
  /* line 319, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .angle-top:before,
  .angle-bottom:after {
    content: none; }
  /* line 324, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .result-box {
    padding: 30% 0; }
  /* line 328, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .process, .main-wrapper-onepage.angles .parallax-testimonials {
    padding: 150px 0; }
  /* line 332, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .parallax-testimonials .owl-pagination {
    bottom: 30px; }
  /* line 336, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .main-wrapper-onepage.angles .gmap {
    height: 450px; }
  /* line 340, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .call-to-action.style-2 {
    padding: 100px 0; }
  /* line 344, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .call-to-action.style-2 h2 {
    font-size: 26px; }
  /* line 348, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .copyright,
  .footer-socials .social-icons {
    text-align: center; }
  /* line 353, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .footer-socials .social-icons,
  .footer-socials .social-icons a {
    float: none;
    margin-top: 7px; }
  /* line 359, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .copyright span {
    line-height: 1.5; }
  /* line 363, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .page-title.style-2 .breadcrumb {
    position: relative;
    text-align: center;
    margin-top: 15px; }
  /* line 369, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .page-title.style-2 .title-text h1 {
    text-align: center;
    font-size: 24px; }
  /* line 374, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .section-wrap.intro {
    padding: 170px 0 100px; }
  /* line 378, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .intro.style-2 .intro-text p {
    font-size: 28px; }
  /* line 382, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .footer-type-3,
  .footer-type-3 .footer-socials {
    text-align: center; }
  /* line 387, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  #ytb-wrap .hero-text {
    font-size: 50px; }
  /* line 391, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .sidenav .container-fluid,
  .sidenav .container {
    width: 100%; } }

@media (max-width: 640px) {
  /* line 401, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .overlay-menu ul li a {
    font-size: 26px; }
  /* line 405, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .section-wrap.intro {
    padding: 150px 0 70px; }
  /* line 409, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .intro.style-2 .intro-text p {
    font-size: 24px; } }

@media (max-width: 480px) {
  /* line 418, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-type-3 .logo-container a {
    max-width: 260px; }
  /* line 423, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .entry-comments .comment-avatar {
    width: 60px; }
  /* line 427, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .entry-comments .comment-content {
    padding-left: 80px; }
  /* line 431, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .comment-reply {
    padding-left: 30px; }
  /* line 435, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav-tabs > li {
    width: 100%;
    margin-bottom: 10px; }
  /* line 440, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .nav.nav-tabs > li > a {
    margin-right: 0; }
  /* line 444, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .page-404 h1 {
    font-size: 80px; }
  /* line 448, C:/Users/redbu/htdocs/_mein-zeugs/static.gartengestaltung-roessle.de/webroot/css/sass/responsive.scss */
  .masonry-grid .work-item.quarter,
  .masonry-grid .work-item,
  .works-grid .work-item {
    width: 100%; } }

/*# sourceMappingURL=../../sass */